import Vuex from 'vuex'

export default () => {
  return new Vuex.Store({
    state: () => ({
      models: null,
      types: null,
      activeModal: null,
      news: null,
      selectedMerk: null,
      comparedCars: null,
      selectedModel: null,
      filterOptions:[
        {friendly:'laagste-actieradius', name:'Laagste actieradius', webserviceField: 'nLActieradius', webserviceSort:1},
        {friendly:'hoogste-actieradius', name:'Hoogste actieradius', webserviceField: 'nHActieradius', webserviceSort:-1},
        {friendly:'laagste-verbruik', name:'Laagste verbruik', webserviceField: 'nLVerbruik', webserviceSort:1},
        {friendly:'hoogste-verbruik', name:'Hoogste verbruik', webserviceField: 'nHVerbruik', webserviceSort:-1},
        {friendly:'prijs-oplopend', name:'Prijs oplopend', webserviceField: 'nLPrijs', webserviceSort:1},
        {friendly:'prijs-aflopend', name:'Prijs aflopend', webserviceField: 'nHPrijs', webserviceSort:-1},
        {friendly:'nieuw-verwacht', name:"Nieuwe auto's", webserviceField: 'bActueel', webserviceSort:1},
      ],
      filterOptionsStats:[
        {friendly:'actieradius', name:'Actieradius', webserviceField: 'nActieradius', webserviceSort:-1, description: 'Een belangrijke vraag bij elektrische voertuigen is “Hoe ver kan ie” oftewel wat is de actieradius.\n' + '\n' + 'Actieradius verwijst naar de afstand die een voertuig, zoals een elektrische auto, kan afleggen zonder te stoppen om bij te laden. Bij elektrische voertuigen wordt de actieradius bepaald door de capaciteit van de accu en het energieverbruik van de auto. Een grotere actieradius betekent dat de auto verder kan rijden op één enkele acculading, wat handig is voor langere reizen en minder frequente laadbeurten.'},

        {friendly:'verbruik', name:'Verbruik', webserviceField: 'nIngeschatVerbruik', webserviceSort:1, description:'Welke elektrische auto heeft een hoog energieverbruik en welke een laag energieverbruik? In het onderstaande overzicht worden alle modellen van WhatTheRange gesorteerd op basis van hun energieverbruik, zodat u eenvoudig kunt vergelijken welke auto’s een efficiënter verbruik hebben.' },
        {friendly:'prijs', name:'Prijs', webserviceField: 'nConsumentenprijs', webserviceSort:-1, description:'Elektrische auto\'s zijn niet alleen voor de rijken. De prijzen dalen snel en er zijn goedkopere modellen beschikbaar. Bovendien zijn ze goedkoper in onderhoud en brandstofkosten, dus op de lange termijn kan het voordeliger zijn. Hieronder vindt je een overzicht van de elektrische auto\'s in de WhatTheRange-database met de hoogste en laagste prijzen.'},
        {friendly:'koppel', name:'Koppel Nm', webserviceField: 'Koppel_Nm', webserviceSort:-1, description:'Welke elektrische auto heeft de meeste power oftewel koppel? Het koppel van een elektromotor bepaalt de trekkracht van het voertuig en is een belangrijke factor voor de algehele prestaties en rijervaring van een elektrische auto.'},
        {friendly:'acceleratie', name:'Acceleratie', webserviceField: 'intAcceleratie', webserviceSort:1, description: 'Elektrische auto\'s staan bekend om hun snelle acceleratie dankzij de direct beschikbare koppel van de elektromotoren.\n' + '\n' + 'Hieronder vind je een overzicht van de elektrische auto\'s in de WhatTheRange-database met de hoogste acceleratie en de laagste'},
        {friendly:'top-snelheid', name:'Top snelheid', webserviceField: 'intTopsnelheid', webserviceSort:-1, description: 'Die van mij kan sneller!\n' + '\n' + 'Hieronder vindt je een overzicht van de elektrische auto\'s in de WhatTheRange-database met de hoogste en laagste topsnelheden.'},
        {friendly:'accu-capaciteit', name:'Accu capaciteit', webserviceField: 'Accu_capaciteit_kWh', webserviceSort:-1, description: 'Accu capaciteit is de hoeveelheid energie die een elektrische auto kan opslaan. Dit wordt uitgedrukt in kWh en bepaalt hoeveel kilometers je kunt rijden op een volle accu. Gelukkig worden accu\'s steeds beter en zijn er nu modellen op de markt met een capaciteit van meer dan 100 kWh. Kies de auto met de juiste accu capaciteit voor jouw rijstijl en behoeften.'},
        // {friendly:'laad-snelheid', name:'Laad snelheid', webserviceField: 'nLaadsnelheid', webserviceSort:-1,},
        {friendly:'inhoud-bagage', name:'Inhoud bagage', webserviceField: 'nInhoudBagageruimteMax', webserviceSort:-1, description: 'De bagageruimte van elektrische auto\'s varieert sterk, afhankelijk van het merk, model en ontwerp. Sommige modellen hebben een ruime kofferbak, terwijl andere extra opbergruimte bieden in de \'frunk\' (voorste kofferbak), omdat ze geen traditionele verbrandingsmotor hebben. Hieronder vind je\n' + '\n' + 'Vergeet niet dat de gegevens in de WhatTheRange-database regelmatig worden bijgewerkt, dus het is belangrijk om de meest recente informatie te raadplegen bij het vergelijken van elektrische auto\'s.'},
        {friendly:'trekgewicht', name:'Trekgewicht', webserviceField: 'nAanhangerGeremd', webserviceSort:-1, description: 'Wil jij graag een caravan of aanhanger trekken met je elektrische auto? Let dan op het trekgewicht! Dat is het maximale gewicht dat jouw auto veilig kan trekken. \n' + '\n' + 'Gelukkig bieden steeds meer EV-modellen een hoog trekgewicht, dus je hoeft geen zware dobber te hebben aan het kiezen van jouw droomcaravan. Kies simpelweg de elektrische auto die past bij jouw trekbehoeften en rij veilig de weg op. \n' + '\n' + 'Dus, houd het trekgewicht in gedachten bij het kiezen van jouw elektrische auto en maak van jouw volgende roadtrip een onvergetelijk avontuur!'},


      ]
    }),
    getters:{
      getModels(state){
        return state.models
      },
      getTypes(state){
        return state.types
      },
      getNews(state){
        return state.news
      },
      getComparedCars(state){
        return state.comparedCars
      },



      getSelectedMerk(state){
        return state.selectedMerk;
      },
      getSelectedModel(state){
        return state.selectedModel;
      },
      getActiveModal(state){
        return state.activeModal;
      },

    },
    mutations: {
      setSelectedMerk(state, merk){
        state.selectedMerk = merk;
      },
      setSelectedModel(state, model){
        state.selectedModel = model;
      },
      setComparedCars(state, comparedCars){
        state.comparedCars = comparedCars;
      },

      setActiveModal(state, activeModal){
        state.activeModal = activeModal;
      },
      setNews(state, news){
        state.news = news;
      },
    },

    actions:{
      async getModels({  state, commit  }) {
        if(state.models) return state.models;
        await this.$axios.get('/api/ModelViewv3').then((response) => {
          return state.models = response.data
        })
      },
      async getTypes({  state, commit  }) {
        if(state.types) return state.types;
        await this.$axios.get('/api/ModelViewv2/types').then((response) => {
          return state.types = response.data
        })
      },
      async nuxtServerInit({  state, dispatch  }) {
        await dispatch('getModels')
        await dispatch('getTypes')
      },

      async getNews({ state, commit }, id) {
        return this.$axios.get('/api-review/autotests?nmodelid[]=' + id)
          .then((response) => {
            const currentDate = new Date();

            const filteredNews = response.data.data.filter((item) => {
              const publicationDate = new Date(item.publication_on);
              const timeDifference = currentDate.getTime() - publicationDate.getTime();
              const daysDifference = timeDifference / (1000 * 3600 * 24);

              if (item && item.id === 30073) {
                // Exclude the object from the resulting array
                return false;
              }

              // Count the number of <p> tags in item.body
              const numOfPTags = (item.body.match(/<p>/g) || []).length;

              // Cut off the data after 4 <p> tags
              if (numOfPTags > 5) {
                item.body = item.body.slice(0, item.body.indexOf('</p>', item.body.indexOf('</p>') + 1));
              }
              console.log(numOfPTags);

              return daysDifference > 3;
            });

            commit('setNews', filteredNews);

            return state.news;
          });
      }



    },

  })
}
